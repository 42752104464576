import { VariantType, useSnackbar } from 'notistack'
import { useDocumentUpload } from './useDocumentUpload'
import { useOpenState } from './../../../hooks/useOpenState'
import { useVisit } from './useVisit'
import { useQueryPatientDocuments, useQueryPatientNotes } from './../../../api'
import { useTabs } from './useTabs'
import { UseVisitDetailHookProps, VisitDetailPageProps } from '../types'
import { MainVisitContext, PatientContext } from '../../../providers'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useVisitDetailsQuery } from '../../../api/visit/hooks'
import { useParams } from 'react-router-dom'
import { usePatientHistory } from './usePatientHistory'
import { usePageTitle } from '../../../hooks/usePageTitle'
import { useHorizontalIntersect } from './useHorizontalIntersect'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useNewNoteForm } from './useNewNoteForm'
import { useScroll } from '../../../hooks'

export const usePage = (
    props: UseVisitDetailHookProps,
): VisitDetailPageProps => {
    const { visitId } = useParams()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { mainVisit, setMainVisit } = useContext(MainVisitContext)

    const scrollStatus = useScroll()
    const showTabs =
        scrollStatus.scrollDirection === 'up' || !scrollStatus.scrollDirection

    const [importantNotesViewed, setImportantNotesViewed] = useState(false)
    const [isTimeClicked, setIsTimeClicked] = useState(false)

    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false)

    const { activeTab, handleTabChange } = useTabs()
    const intersectElement = useHorizontalIntersect()

    const { activePatient, setActivePatient } = useContext(PatientContext)
    const { visitData, visitDetailsLoading } = useVisitDetailsQuery(visitId)

    const patientNotesQuery = useQueryPatientNotes(false, activePatient?.id)
    const unreadNotes = useMemo(
        () =>
            patientNotesQuery.data?.data.filter(
                (item) => !item.readedByLoginUser,
            ),
        [patientNotesQuery.data?.data],
    )

    const visitForm = useVisit(visitData)

    const importantNotesQuery = useQueryPatientNotes(true, activePatient?.id)
    const patientDocumentsQuery = useQueryPatientDocuments(activePatient?.id)
    const {
        handleFileUpload,
        uploadLoading,
        handleButtonClick,
        fileInputRef,
        newUploadedFileName,
        setNewUploadedFileName,
        handleClosePreview,
        isPreviewOpen,
        fileData,
        handleSendUpload,
    } = useDocumentUpload()
    const patientHistory = usePatientHistory()

    const importantNoteModal = useOpenState()

    const noteModal = useOpenState()
    const newNoteModal = useOpenState()
    const newNoteForm = useNewNoteForm(newNoteModal.handleClose)

    const getSnackbar = useCallback(
        (text: string, variant?: VariantType) => {
            enqueueSnackbar(text, {
                variant: variant || 'success',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                action: (key) => (
                    <IconButton onClick={() => closeSnackbar(key)}>
                        <Close color='secondary' />
                    </IconButton>
                ),
            })
        },
        [closeSnackbar, enqueueSnackbar],
    )

    useEffect(() => {
        if (visitData) {
            setActivePatient(visitData?.patient)
        }
    }, [
        setActivePatient,
        visitData,
        visitForm.carePlanMethods,
        visitForm.formMethods,
    ])

    useEffect(() => {
        if (!visitData) return
        !visitData.canEdit &&
            getSnackbar(
                'Návštěvu nemůžete editovat - Vaše změny nebudou uloženy.',
                'warning',
            )
        !!visitData.reasonNonDone?.length &&
            getSnackbar(
                `Návštěva neprovedena: ${visitData.reasonNonDone}.`,
                'warning',
            )
        !!visitData.reasonReject?.length &&
            visitData.state !== 'Sent' &&
            visitData.state !== 'Draft' &&
            getSnackbar(
                `Návštěva vrácena: ${visitData.reasonReject}.`,
                'warning',
            )
    }, [closeSnackbar, enqueueSnackbar, getSnackbar, visitData])

    usePageTitle(`Detail návštěvy - ${visitId}`)

    useEffect(() => {
        if (!!!importantNotesQuery?.data) return

        if (importantNotesQuery.data.totalItems >= 1 && !importantNotesViewed) {
            importantNoteModal.handleOpen()
            return setImportantNotesViewed(true)
        }
    }, [importantNotesQuery.data, importantNoteModal, importantNotesViewed])

    useEffect(() => {
        // todo: BOUND TO FAIL

        const lsMainVisit = localStorage.getItem('mainVisit')
        const parsedData = lsMainVisit && JSON.parse(lsMainVisit)

        if (!!mainVisit && mainVisit !== parsedData) {
            return localStorage.setItem('mainVisit', JSON.stringify(mainVisit))
        }

        if (!mainVisit && parsedData) {
            return setMainVisit(parsedData)
        }
    }, [mainVisit, setMainVisit])

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 30) {
                setIsScrollUpVisible(true)
            } else {
                setIsScrollUpVisible(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return {
        mainVisit,
        activeTab,
        newUploadedFileName,
        setNewUploadedFileName,
        handleSendUpload,
        handleTabChange,
        activePatient,
        isPreviewOpen,
        fileData,
        handleClosePreview,
        visitData,
        visitDetailsLoading,
        importantNoteModal,
        importantNotesQuery,
        patientDocumentsQuery,
        handleFileUpload,
        uploadLoading,
        handleButtonClick,
        fileInputRef,
        visitForm,
        patientHistory,
        intersectElement,
        isTimeClicked,
        setIsTimeClicked,
        noteModal,
        newNoteModal,
        newNoteForm,
        patientNotesQuery,
        unreadNotes,
        scrollStatus,
        showTabs,
        isScrollUpVisible,
    }
}
