import {
    Autocomplete,
    Avatar,
    Box,
    Chip,
    MenuItem,
    Stack,
    StackProps,
    TextField,
    Typography,
} from '@mui/material'

import { twMerge } from 'tailwind-merge'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useUIDSeed } from 'react-uid'
import { FilterItems } from './types'

export interface FilterItemValue<TValue> {
    active: boolean
    value: TValue
    name: string
    prefix?: string
    icon?: string
}

export interface FilterGroupProps<TFilterType, TValue>
    extends Omit<StackProps, 'children'> {
    title?: string
    isSelect?: boolean
    type: TFilterType
    items: FilterItemValue<TValue>[]
    handleFilterActivate: (args: {
        item: FilterItemValue<TValue>
        group: FilterItems<TFilterType, TValue>
    }) => void
    resetFilterGroup: (groupType: TFilterType) => void
}

export const FilterGroup = <TFilterType, TValue>(
    props: FilterGroupProps<TFilterType, TValue>,
) => {
    const {
        title,
        type,
        items,
        isSelect,
        handleFilterActivate,
        resetFilterGroup,
    } = props

    const noneFilterActive = useMemo(
        () => !items.some(({ active }) => active),
        [items],
    )
    const uid = useUIDSeed()

    const selectedItemsMemo = useMemo(() => {
        return items.filter((item) => item.active)
    }, [items])

    const [selectedItems, setSelectedItems] = useState<any>(selectedItemsMemo)

    useEffect(() => {
        setSelectedItems(selectedItemsMemo)
    }, [selectedItemsMemo])

    const handleChange = useCallback(
        (
            event: React.SyntheticEvent<Element, Event>,
            newValue: FilterItemValue<string>[],
        ) => {
            const addedItem = newValue.find(
                (item: FilterItemValue<string>) =>
                    !selectedItems.includes(item),
            )
            const removedItem = selectedItems.find(
                (item: FilterItemValue<string>) => !newValue.includes(item),
            )

            if (addedItem) {
                handleFilterActivate({
                    item: addedItem,
                    group: { title, type, items },
                } as {
                    item: FilterItemValue<TValue>
                    group: FilterItems<TFilterType, TValue>
                })
            } else if (removedItem) {
                handleFilterActivate({
                    item: removedItem,
                    group: { title, type, items },
                } as {
                    item: FilterItemValue<TValue>
                    group: FilterItems<TFilterType, TValue>
                })
            }

            setSelectedItems(newValue)
        },
        [selectedItems, handleFilterActivate, title, type, items],
    )

    // console.log(items)
    // console.log(items.filter((item) => item.value === '4679'))

    //console.log(items.length)
    return (
        <Stack
            key={title}
            className='mb-4'
        >
            <Stack className='mb-3 flex-row items-baseline justify-between'>
                <Typography>{title}</Typography>
                <Box className='h-px w-4 bg-gray-600' />
            </Stack>
            <Stack className='flex-row flex-wrap gap-1'>
                {isSelect ? (
                    <>
                        <Autocomplete
                            sx={{ width: '100%' }}
                            multiple
                            id={`autocomplete-${title}`}
                            options={items}
                            value={selectedItems}
                            onChange={handleChange}
                            getOptionLabel={(option) =>
                                option.name + option.value
                            }
                            disableCloseOnSelect
                            noOptionsText={
                                <span style={{ fontSize: '14px' }}>
                                    Nenalezeny žádné výsledky
                                </span>
                            }
                            isOptionEqualToValue={(option, value) => {
                                return option.value === value.value
                            }}
                            renderOption={(props, option, { selected }) => (
                                <MenuItem
                                    key={uid(
                                        option.value +
                                            option.name +
                                            option.active,
                                    )}
                                    value={option}
                                    sx={{
                                        justifyContent: 'space-between',
                                        fontSize: '14px',
                                        padding: '6px 12px',
                                    }}
                                    {...props}
                                >
                                    {option.name}
                                </MenuItem>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    placeholder={'Vyberte další...'}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderColor: '#f8833b',
                                            fontSize: '14px',
                                            padding: '4px 10px',
                                            backgroundColor: '#f8f8f8',
                                            '&:hover fieldset': {
                                                borderColor: '#f8833b',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            fontSize: '14px',
                                        },
                                    }}
                                />
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option.name}
                                        sx={{
                                            backgroundColor: '#f8833b',
                                            color: '#fff',
                                            fontSize: '14px',
                                            marginRight: '4px',
                                            marginBottom: '4px',
                                            '& .MuiChip-deleteIcon': {
                                                color: '#f8f8f8',
                                                opacity: 0.7,
                                                '&:hover': {
                                                    opacity: 1,
                                                    color: '#f8f8f8',
                                                },
                                            },
                                        }}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                        />
                    </>
                ) : (
                    <>
                        <Chip
                            label='Vše'
                            variant={noneFilterActive ? 'filled' : 'outlined'}
                            color='primary'
                            className={twMerge(
                                noneFilterActive
                                    ? 'bg-primary text-white'
                                    : 'bg-white text-gray-600',
                            )}
                            onClick={() => resetFilterGroup(type)}
                        />
                        {items.map((item) => (
                            <Chip
                                key={uid(item.name)}
                                label={item.name}
                                variant={item.active ? 'filled' : 'outlined'}
                                color='primary'
                                className={twMerge(
                                    item.active
                                        ? 'bg-primary text-white'
                                        : 'bg-white text-gray-600',
                                )}
                                avatar={
                                    item.name?.split(' ').length > 1 ||
                                    item.icon ? (
                                        <Avatar
                                            sx={{
                                                '&.MuiAvatar-root': {
                                                    display: 'none',
                                                },
                                            }}
                                            className='bg-orange-300 text-base'
                                            src={item.icon || ''}
                                            alt={item.name || ''}
                                        >
                                            {!item.icon?.trim() &&
                                                `${
                                                    item.name?.split(' ')[0][0]
                                                }${
                                                    item.name?.split(' ')[1][0]
                                                }`}
                                        </Avatar>
                                    ) : undefined
                                }
                                onClick={() =>
                                    handleFilterActivate({
                                        item,
                                        group: { title, type, items },
                                    })
                                }
                            />
                        ))}
                    </>
                )}
            </Stack>
        </Stack>
    )
}
