import { Menu, MenuItem, Button, Box } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from 'react-router-dom'
import { PatientsCard } from '../types'
const PatientCardMenu = ({
    handleOpenCareAnamneses,
    patient,
    setSelectedPatient,
    canReadCareAnamnesis,
}: {
    patient: PatientsCard
    canReadCareAnamnesis: boolean
    setSelectedPatient: Dispatch<SetStateAction<PatientsCard | null>>
    handleOpenCareAnamneses: () => void
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const navigate = useNavigate()

    return (
        <Box
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                e.stopPropagation()
            }
        >
            <Button
                className='min-w-max p-[6px] shadow-none'
                id='menu-button'
                aria-controls={open ? 'anamnesis-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant='contained'
            >
                <MenuIcon />
            </Button>
            <Menu
                id='anamnesis-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'menu-button',
                }}
            >
                <MenuItem
                    sx={{
                        display:
                            !patient.lastVisitID || patient.lastVisitID === '0'
                                ? 'none'
                                : 'inherit',
                    }}
                    className='text-base'
                    disabled={
                        !patient.lastVisitID || patient.lastVisitID === '0'
                    }
                    onClick={() =>
                        navigate(`/visit-detail/${patient.lastVisitID}`)
                    }
                >
                    Poslední návštěva
                </MenuItem>

                {canReadCareAnamnesis && (
                    <MenuItem
                        className='text-base'
                        onClick={() => {
                            handleOpenCareAnamneses()
                            setSelectedPatient(patient)
                            handleClose()
                        }}
                    >
                        Vstupní dotazník
                    </MenuItem>
                )}
            </Menu>
        </Box>
    )
}

export default PatientCardMenu
