import {
    createPatientNote,
    deletePatientDocument,
    getDocumentFile,
    getPatientContacts,
    getPatientDocuments,
    getPatientMedications,
    getPatientNotes,
    getPatientsFilters,
    postPatientDocument,
    putPatientDocumentInfo,
    putPatientDocumentRotation,
    updatePatientNote,
} from './services'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { AuthContext, PatientContext } from '../../providers'
import type { Contact } from './types'
import type {
    ChangeDocumentFilenameProps,
    ChangeDocumentRotationProps,
    Collection,
    DocumentFile,
    PatientDocument,
    PatientNote,
} from '../../types'
import type { ErrorResponse } from '../user/types'
import type { NewNoteFormProps } from '../../pages/VisitDetailPage/types'
import { DocumentQueryProps, MedicationQueryProps } from '../types'
import { useSnackbar } from 'notistack'
import { AxiosError } from 'axios'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

export const useQueryContacts = (patientId?: number) => {
    const { token } = useContext(AuthContext)
    const contactsQuery = useQuery<Collection<Contact>, ErrorResponse>(
        ['patient', 'contacts', patientId],
        () => getPatientContacts(patientId, token),
        {
            enabled: patientId !== undefined || token !== '',
        },
    )

    return contactsQuery
}

export const useQueryPatientNotes = (
    selectImportant = false,
    patientId?: number,
) => {
    const { token } = useContext(AuthContext)
    const patientNotesQuery = useQuery<Collection<PatientNote>, ErrorResponse>(
        ['patient', 'patientNotes', { patientId, selectImportant }],
        () => getPatientNotes(patientId, token, selectImportant),
        {
            enabled: patientId !== undefined && token !== '',
            staleTime: 1000 * 20,
        },
    )

    return patientNotesQuery
}

export const useNewNoteMutation = (patientId?: number) => {
    const { token } = useContext(AuthContext)
    const queryClient = useQueryClient()
    const newNoteMutation = useMutation(
        (data: NewNoteFormProps) => createPatientNote(patientId, data, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'patient',
                    'patientNotes',
                    { patientId, selectImportant: false },
                ])
            },
        },
    )

    return newNoteMutation
}

export const useUpdateNoteMutation = (selectImportant: boolean = false) => {
    const queryClient = useQueryClient()
    const { token } = useContext(AuthContext)
    const { activePatient } = useContext(PatientContext)
    const updateNoteMutation = useMutation(
        (data: { noteId: number; readedByLoginUser: boolean }) =>
            updatePatientNote(token, data.noteId, data.readedByLoginUser),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'patient',
                    'patientNotes',
                    { patientId: activePatient?.id, selectImportant },
                ])
            },
        },
    )

    return updateNoteMutation
}

export const useQueryPatientDocuments = (patientId?: number) => {
    const { token } = useContext(AuthContext)
    const patientDocumentsQuery = useQuery<
        Collection<PatientDocument>,
        ErrorResponse
    >(
        ['patient', 'patientDocuments', patientId],
        () => getPatientDocuments(patientId, token),
        {
            enabled: patientId !== undefined && token !== '',
            staleTime: 1000 * 20,
        },
    )

    return patientDocumentsQuery
}

export const useQueryDocumentFile = ({
    documentId,
    width,
    height,
    disable = false,
    normalSize = false,
}: DocumentQueryProps) => {
    const { token } = useContext(AuthContext)
    const documentFileQuery = useQuery(
        ['patient', 'documentFile', documentId, normalSize],
        () => getDocumentFile(documentId, token, width, height),
        {
            enabled:
                documentId !== undefined && token !== '' && disable === false,
        },
    )

    return documentFileQuery
}

export const useDocumentMutation = (visitId?: string) => {
    const { token } = useContext(AuthContext)
    const { activePatient } = useContext(PatientContext)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const queryClient = useQueryClient()

    const documentMutation = useMutation(
        async (file: DocumentFile) => {
            const { description, ...fileData } = file
            return await postPatientDocument({
                file: fileData,
                patientId: activePatient?.id,
                token,
                description: description,
                visitID: Number(visitId),
            })
        },
        {
            onSuccess: () => {
                queryClient.refetchQueries([
                    'patient',
                    'patientDocuments',
                    activePatient?.id,
                ])
            },
            onError: (res) => {
                const error = (res as AxiosError).response
                    ?.data as ErrorResponse
                enqueueSnackbar(
                    `Soubor se nepodařilo nahrát: ${error.title}.`,
                    {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        action: (key) => (
                            <IconButton onClick={() => closeSnackbar(key)}>
                                <Close color='secondary' />
                            </IconButton>
                        ),
                    },
                )
                error.detail.length &&
                    enqueueSnackbar(error.detail + '.', {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'center' },
                        action: (key) => (
                            <IconButton onClick={() => closeSnackbar(key)}>
                                <Close color='secondary' />
                            </IconButton>
                        ),
                    })
            },
        },
    )

    return documentMutation
}

export const useMedicationQuery = (
    props: MedicationQueryProps,
    showOnlyCurrent: boolean,
) => {
    const { patientId } = props
    const { token } = useContext(AuthContext)

    const medicationQuery = useQuery(
        ['patient', 'medication', { patientId, showOnlyCurrent }],
        async () => await getPatientMedications({ ...props }),
        {
            enabled: !!patientId && !!token,
        },
    )

    return medicationQuery
}

export const useChangeDocumentInfoMutation = (patientId: number) => {
    const { token } = useContext(AuthContext)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const queryClient = useQueryClient()
    const { mutateAsync: changeDocumentInfoMutation } = useMutation(
        async (data: ChangeDocumentFilenameProps) =>
            await putPatientDocumentInfo(data, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'patient',
                    'patientDocuments',
                    patientId,
                ])

                enqueueSnackbar('Název souboru byl úspěšně změnen.', {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    action: (key) => (
                        <IconButton onClick={() => closeSnackbar(key)}>
                            <Close color='secondary' />
                        </IconButton>
                    ),
                })
            },
        },
    )
    return changeDocumentInfoMutation
}

export const useDeleteDocumentMutation = (patientId: number) => {
    const { token } = useContext(AuthContext)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const queryClient = useQueryClient()
    const { mutateAsync: deletePatientDocumentMutation } = useMutation(
        async (documentId: number) =>
            await deletePatientDocument(documentId, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'patient',
                    'patientDocuments',
                    patientId,
                ])

                enqueueSnackbar('Soubor byl úspěšně smazán.', {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    action: (key) => (
                        <IconButton onClick={() => closeSnackbar(key)}>
                            <Close color='secondary' />
                        </IconButton>
                    ),
                })
            },
        },
    )
    return deletePatientDocumentMutation
}

export const useChangeDocumentRotationMutation = (patientId: number) => {
    const { token } = useContext(AuthContext)

    const queryClient = useQueryClient()
    const { mutateAsync: changeDocumentRotationMutation } = useMutation(
        async (data: ChangeDocumentRotationProps) =>
            await putPatientDocumentRotation(data, token),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'patient',
                    'patientDocuments',
                    patientId,
                ])
            },
        },
    )
    return changeDocumentRotationMutation
}

export const usePatientsFilterQuery = () => {
    const { token, user } = useContext(AuthContext)

    const filterUsersQuery = useQuery(
        ['patientsFilters', user?.id],
        async () => await getPatientsFilters(token),
        {
            //  enabled: !!patientId,
        },
    )

    return filterUsersQuery
}
