import { memo, type FC } from 'react'
import type { PatientsFilterHookProps, PatientsFilterProps } from '../types'
import { Stack, Chip, Avatar, IconButton, InputAdornment } from '@mui/material'
import { Close, Search, Tune } from '@mui/icons-material'
import { wrap } from '../../../utils'
import { useUIDSeed } from 'react-uid'
import { usePatientsFilters } from '../hooks/usePatientsFilters'
import { FilterModal } from '../../../utils/FilterModal'
import InputStyledUncontrolled from '../../../components/InputStyledUncontrolled'

const PatientsFiltersView: FC<PatientsFilterProps> = (props) => {
    const {
        isLoading,
        filterModalState,
        patientsFilters,
        handleFilterActivate,
        resetFilterGroup,
        getSameFilterGroup,
        resetFilterAllActiveGroup,
        handleUseFilters,
        actualFilter,
        searchPatientName,
        setSearchPatientName,
        totalPatients,
        handleDeleteItem,
        isPatientSeachOpen,
        toggleSearchPatientName,
    } = props

    const { handleOpen, isOpen, handleClose } = filterModalState

    const uid = useUIDSeed()

    return !isLoading ? (
        <Stack
            direction='column'
            className='mb-4 flex-wrap  justify-between gap-4'
        >
            {
                <FilterModal
                    open={isOpen}
                    handleUseFilters={handleUseFilters}
                    filters={patientsFilters}
                    resetFilterAllActiveGroup={resetFilterAllActiveGroup}
                    handleFilterActivate={handleFilterActivate as any}
                    resetFilterGroup={resetFilterGroup as any}
                    title='Filtry kartotéky'
                    {...filterModalState}
                    handleClose={() => {
                        handleClose()
                    }}
                />
            }
            <Stack
                direction={'row'}
                className='flex-wrap justify-between gap-4'
            >
                <Stack
                    direction={'row'}
                    width={'100%'}
                    className='flex flex-wrap items-center gap-1'
                >
                    {actualFilter &&
                        actualFilter.map((item) => (
                            <Chip
                                key={uid(item.name)}
                                color='primary'
                                variant='outlined'
                                avatar={
                                    item.name?.split(' ').length > 1 ||
                                    item.icon ? (
                                        <Avatar
                                            sx={{
                                                '&.MuiAvatar-root': {
                                                    display: 'none',
                                                },
                                            }}
                                            className='bg-orange-300 text-base'
                                            src={item.icon || ''}
                                            alt={item.name || ''}
                                        >
                                            {!item.icon?.trim() &&
                                                `${
                                                    item.name?.split(' ')[0][0]
                                                }${
                                                    item.name?.split(' ')[1][0]
                                                }`}
                                        </Avatar>
                                    ) : undefined
                                }
                                label={item.name}
                                onDelete={() => {
                                    handleFilterActivate({
                                        item,
                                        group: getSameFilterGroup(item.type)!,
                                    })

                                    handleDeleteItem(item)
                                }}
                                onClick={() => {
                                    handleFilterActivate({
                                        item,
                                        group: getSameFilterGroup(item.type)!,
                                    })
                                    handleDeleteItem(item)
                                }}
                            />
                        ))}

                    <Chip
                        color='primary'
                        label='Zvolit filtry'
                        onClick={handleOpen}
                        icon={<Tune fontSize='small' />}
                    />
                </Stack>
            </Stack>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent={'space-between'}
            >
                <IconButton onClick={toggleSearchPatientName}>
                    {isPatientSeachOpen ? <Close /> : <Search />}
                </IconButton>
                {isPatientSeachOpen && (
                    <InputStyledUncontrolled
                        placeholder=''
                        value={searchPatientName}
                        name='search'
                        empty='Hledat pacienty...'
                        type='text'
                        label=''
                        disabled={isLoading}
                        onChange={(e) => setSearchPatientName(e.target.value)}
                        styleWrapper={{
                            marginLeft: 8,
                            transition: 'width 0.3s',
                            width: isPatientSeachOpen ? '100%' : '0',
                        }}
                        style={{
                            marginTop: 0,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Search fontSize='small' />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}

                <span className='text-nowrap ml-4 whitespace-nowrap'>
                    <b>{totalPatients}</b> pacientů
                </span>
            </Stack>
        </Stack>
    ) : null
}

export const PatientsFilters: FC<PatientsFilterHookProps> = memo(
    wrap(PatientsFiltersView, usePatientsFilters),
)
