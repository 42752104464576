import { useCallback, useContext, useEffect, useState } from 'react'
import { useOpenState } from '../../../hooks'
import {
    PatientFilterQueries,
    PatientsCardFilterType,
    PatientsFilterHookProps,
    PatientsFilterItems,
    PatientsFilterProps,
} from '../types'
import { PatientsFiltersContext } from '../../../providers/PatientFiltersProvider'
import { useFilterReset } from '../../BulletinPage/hooks/useBulletinFilterReset'
import { useFiltersActivate } from '../../../utils/useFiltersActivate'
import {
    getPatientsCardFilters,
    singleSelectPatientsGroup,
} from '../constatns/filters'
import { useSearchParams } from 'react-router-dom'
import { usePatientsFilterQuery } from '../../../api'

export const usePatientsFilters = (
    props: PatientsFilterHookProps,
): PatientsFilterProps => {
    const filterModalState = useOpenState()
    const { patientsQueryFilters } = useContext(PatientsFiltersContext)

    const { data: dynamicFilters } = usePatientsFilterQuery()

    const [intialLoad, setIntialLoad] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams()

    const [isPatientSeachOpen, setIsPatientSeachOpen] = useState(
        searchParams.get('patientName') !== null,
    )

    const [searchPatientName, setSearchPatientName] = useState<string>(
        searchParams.get('patientName') || '',
    )
    const [debouncedSearchPatientName, setDebouncedSearchPatientName] =
        useState<string>('')

    const [patientsFilters, setPatientsFilters] = useState<
        PatientsFilterItems[]
    >([])

    useEffect(() => {
        if (patientsQueryFilters && intialLoad && dynamicFilters) {
            const disableAllFilters = dynamicFilters.data.map((item) => ({
                ...item,
                items: item.items.map((_item) => ({
                    ..._item,
                    active: false,
                })),
            }))

            setPatientsFilters(
                getPatientsCardFilters(disableAllFilters, patientsQueryFilters),
            )
        }
    }, [patientsQueryFilters, intialLoad, dynamicFilters])

    const { resetFilterGroup, resetFilterAllActiveGroup } = useFilterReset({
        filterNameType: 'patients',
        filters: patientsFilters,
        setFilters: setPatientsFilters,
        singleSelectGroup: singleSelectPatientsGroup,
    })

    const {
        handleFilterActivate,
        extractedActiveFiltersWithType: activeFilters,
        getActiveGroup,
    } = useFiltersActivate<PatientsCardFilterType, string | boolean>({
        filters: patientsFilters,
        setFilters: setPatientsFilters,
        singleSelectGroup: singleSelectPatientsGroup,
    })

    const [actualFilter, setActualFilter] = useState(activeFilters)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchPatientName(searchPatientName)
        }, 300)

        return () => {
            clearTimeout(handler)
        }
    }, [searchPatientName])

    useEffect(() => {
        const updatedParams = new URLSearchParams(searchParams.toString())

        if (debouncedSearchPatientName.length >= 3) {
            updatedParams.set('patientName', debouncedSearchPatientName)
            updatedParams.set('page', '1')
        } else if (
            debouncedSearchPatientName.length < 3 &&
            searchParams.get('patientName')
        ) {
            updatedParams.delete('patientName')
            updatedParams.set('page', '1')
        }

        // Only update searchParams if changes are needed
        if (updatedParams.toString() !== searchParams.toString()) {
            setSearchParams(updatedParams)
        }
    }, [debouncedSearchPatientName, searchParams, setSearchParams])

    const toggleSearchPatientName = useCallback(() => {
        if (isPatientSeachOpen) {
            setSearchPatientName('')
        }
        setIsPatientSeachOpen((prev) => !prev)
    }, [isPatientSeachOpen])

    useEffect(() => {
        if (patientsFilters.length > 0 && intialLoad && dynamicFilters) {
            setActualFilter(activeFilters)
            setIntialLoad(false)
        }
    }, [patientsFilters, intialLoad, activeFilters, dynamicFilters])

    useEffect(() => {
        setActualFilter(activeFilters)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientsQueryFilters])

    const handleUseFilters = useCallback(
        (handleClose: () => void) => {
            const updatedFormat = activeFilters.reduce((acc, filter) => {
                if (filter.active) {
                    acc[filter.type] = acc[filter.type] ?? []

                    if (Array.isArray(filter.value)) {
                        acc[filter.type] = [
                            ...acc[filter.type],
                            ...filter.value,
                        ]
                    } else if (filter.value !== null) {
                        acc[filter.type].push(filter.value)
                    }
                }
                return acc
            }, {} as PatientFilterQueries)

            const params: Record<string, any> = {
                page: '1',
                ...updatedFormat,
            }

            if (debouncedSearchPatientName.trim().length >= 3) {
                params.patientName = debouncedSearchPatientName
            }

            setSearchParams(params)
            handleClose()
        },
        [activeFilters, setSearchParams, debouncedSearchPatientName],
    )

    const handleDeleteItem = useCallback(
        (item: any) => {
            const updatedFilters = activeFilters.filter((filter) => {
                return !(
                    filter.value === item.value && filter.type === item.type
                )
            })

            const updatedFormat = updatedFilters.reduce((acc, filter) => {
                acc[filter.type] = acc[filter.type] ?? []
                ;(acc[filter.type] as Array<string | boolean>).push(
                    filter.value,
                )

                return acc
            }, {} as PatientFilterQueries)

            const params: Record<string, any> = {
                page: '1',
                ...updatedFormat,
            }

            if (debouncedSearchPatientName.trim().length >= 3) {
                params.patientName = debouncedSearchPatientName
            }

            setActualFilter(activeFilters)
            setSearchParams(params)
        },
        [activeFilters, setSearchParams, debouncedSearchPatientName],
    )

    const getSameFilterGroup = useCallback(
        (groupType: PatientsCardFilterType) => {
            return patientsFilters.find(({ type }) => type === groupType)
        },
        [patientsFilters],
    )

    return {
        patientsFilters,
        actualFilter,
        activeFilters,
        handleFilterActivate,
        resetFilterGroup,
        filterModalState,
        handleUseFilters,
        resetFilterAllActiveGroup,
        handleDeleteItem,
        getSameFilterGroup,
        searchPatientName,
        setSearchPatientName,
        isPatientSeachOpen,
        toggleSearchPatientName,
        ...props,
    }
}
