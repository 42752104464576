import { Controller } from 'react-hook-form'
import { OutlinedInput, InputLabel, Typography, Stack } from '@mui/material'

interface InputStyledProps {
    name: string
    label: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    value?: string
    type?:
        | 'text'
        | 'email'
        | 'password'
        | 'number'
        | 'url'
        | 'tel'
        | 'date'
        | 'checkbox'
        | 'radio'
    empty?: string
    positiveOnly?: boolean
    placeholder?: string
    disabled: boolean
    max?: number
    min?: number
    style?: React.CSSProperties
    styleWrapper?: React.CSSProperties
    readOnly?: boolean
    InputProps?: any
    error?: string
}

const InputStyledUncontrolled = ({
    name,
    label,
    type = 'text',
    disabled,
    empty,
    error,
    placeholder = '',
    readOnly,
    onChange,
    value,
    styleWrapper,
    InputProps,
    style,
    max,
    min,
    positiveOnly = false,
}: InputStyledProps) => {
    const inputProps =
        type === 'number' && positiveOnly
            ? {
                  min: 0,
                  ...(min !== undefined ? { min } : {}),
                  ...(max !== undefined ? { max } : {}),
              }
            : InputProps
    return (
        <Stack
            spacing={1}
            style={styleWrapper}
        >
            <InputLabel className='text-sm'>{label}</InputLabel>

            <OutlinedInput
                value={value ?? ''}
                style={style}
                disabled={disabled}
                readOnly={readOnly}
                name={name}
                placeholder={
                    empty
                        ? empty
                        : placeholder
                        ? `Uveďte ${placeholder} zde...`
                        : ''
                }
                className='bg-white-reduced text-sm'
                type={type}
                fullWidth
                onChange={onChange}
                size='small'
                error={!!error}
                inputProps={inputProps}
                sx={{
                    marginBottom: 2,
                    '&.Mui-disabled': {
                        backgroundColor: '#fde0ce',
                        color: 'rgba(0, 0, 0, 0.87) !important',
                        opacity: 1,
                        '& .MuiOutlinedInput-input': {
                            color: 'rgba(0, 0, 0, 0.87) !important',
                            '-webkit-text-fill-color':
                                'rgba(0, 0, 0, 0.87) !important',
                            '-webkit-text-stroke': '0',
                        },
                    },
                }}
            />

            {error && (
                <Typography className='pt-1 text-sm text-error-2'>
                    {error}
                </Typography>
            )}
        </Stack>
    )
}

export default InputStyledUncontrolled
