import { Box, Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import type { DashboardProps } from '../types'
import { DashboardButton } from './DashboardButton'
import PersonAddIcon from '@mui/icons-material/PersonAdd'

import GroupIcon from '@mui/icons-material/Group'
import { useOpenState } from '../../../hooks'
import AddPatientModalForm from '../../PatientsPage/components/AddPatientModalForm'

export const OfficeDashboard = (props: DashboardProps) => {
    const { data, isLoading } = props
    const navigate = useNavigate()
    const { isOpen, handleClose, handleOpen } = useOpenState()

    return (
        <Box
            component='section'
            className='pb-5 pt-2'
        >
            <Typography className='pb-5 font-medium text-gray-700'>
                Moje kancelář
            </Typography>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={6}
                >
                    <DashboardButton
                        disabled={isLoading || !data?.returnedVisitsCount}
                        countNumber={data?.returnedVisitsCount}
                        countLoading={isLoading}
                        label='Vrácené návštěvy'
                        onClick={() => navigate('/visits-returned')}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <Button
                        disabled={isLoading || data?.canCreatePatient === false}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 3,
                            width: '100%',
                            height: '100%',
                            borderRadius: '6px',
                            border: '1px solid',
                            borderColor: '#f88035',
                            bgcolor: '#f88035',
                            color: '#F8F8F8',
                            padding: '8px 12px',
                            cursor: 'pointer',
                            '&:hover': {
                                bgcolor: '#f88035',
                            },

                            ...((isLoading ||
                                data?.canCreatePatient === false) && {
                                bgcolor: '#D3D3D3',
                                color: 'rgba(248, 248, 248, 0.7)',
                                borderColor: '#B0B0B0',
                                cursor: 'not-allowed',
                                '&:hover': {
                                    bgcolor: '#D3D3D3',
                                },
                            }),
                        }}
                        onClick={() => handleOpen()}
                    >
                        <Typography
                            sx={{
                                color:
                                    isLoading ||
                                    data?.canCreatePatient === false
                                        ? '#9c9c9c'
                                        : '#F8F8F8',
                            }}
                            className='mr-auto text-[0.875rem] lowercase  sm:text-[1rem]'
                        >
                            Přidat pacienta
                        </Typography>
                        <PersonAddIcon className='ml-auto' />
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <DashboardButton
                        disabled={isLoading || !data?.draftVisitsCount}
                        countNumber={data?.draftVisitsCount}
                        countLoading={isLoading}
                        label='Návštěvy ke zpracování'
                        onClick={() => navigate('/visits-inprogress')}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    {/* 

                        <Button
                            className='text-white flex h-full w-full cursor-pointer flex-col items-center justify-between gap-3 rounded border border-solid bg-[#F8F8F8] px-3  py-2 text-orange-500 hover:bg-[#F8F8F8]'
                            onClick={() => console.log('Přečíst medikaci')}
                        >
                            <Typography className='mr-auto text-[0.875rem] lowercase text-orange-500 sm:text-[1rem]'>
                                Přečíst medikaci
                            </Typography>
                            <MedicationIcon className='ml-auto' />
                        </Button>
                    
                    */}
                    <Button
                        className='text-white flex h-full w-full cursor-pointer flex-col items-center justify-between gap-3 rounded border border-solid bg-[#F8F8F8] px-3  py-2 text-orange-500 hover:bg-[#F8F8F8]'
                        onClick={() => navigate('/patients?archived=false')}
                    >
                        <Typography className='mr-auto text-[0.875rem] lowercase text-orange-500 sm:text-[1rem]'>
                            zobrazit kartotéku
                        </Typography>
                        <GroupIcon className='ml-auto' />
                    </Button>
                </Grid>
            </Grid>
            {/* 
                     <Box className='mt-6 flex items-center justify-center '>
                        <Button
                            type='button'
                            className='lowercase'
                            onClick={() => navigate('/patients')}
                        >
                            zobrazit kartotéku
                            <GroupIcon className='ml-2' />
                        </Button>
                    </Box>
                */}

            <AddPatientModalForm
                open={isOpen}
                handleClose={handleClose}
            />
        </Box>
    )
}
