import { Dispatch, SetStateAction } from 'react'
import {
    LoadingState,
    OpenStateProps,
    TrackLocationProps,
} from '../../../types'
import { ModalComponentProps } from '../../../types/components'
import { Control, UseFormReturn } from 'react-hook-form'
import { FilterItems } from '../../../utils/types'

export interface PatientsPageProps {
    newPatientsModalState: OpenStateProps
    isMultiEditMode: boolean
    setIsMultiEditMode: Dispatch<SetStateAction<boolean>>
}
export interface AddPatientAnamnesisFormModalProps
    extends Pick<ModalComponentProps, 'handleClose' | 'open'> {
    step: number
    anamnesisId: number
    setStep: Dispatch<SetStateAction<number>>
    selectedPatientAnamnesisID: number
    anamnesisStep: number
    canEditCareAnamnesis: boolean
    canReadCareAnamnesis: boolean
}

export interface AddPatientsAnamnesisFormSteps {
    isLoading: boolean
    error: boolean
}

export type AddPatientsAnamnesisFormModalHook = Omit<
    AddPatientAnamnesisFormModalProps,
    | 'formMethods'
    | 'submitForm'
    | 'step'
    | 'setStep'
    | 'formData'
    | 'setFormData'
>

export type EditPatientAnamnesisModalProps = {
    open: boolean
    patient: PatientsCard
    handleClose: () => void
    setSelectedPatientAnamnesis: Dispatch<SetStateAction<CareAnamneses | null>>
}

export enum ECareAnamnesisState {
    Step0 = 0,
    Step1 = 1,
    Step2 = 2,
    Step3 = 3,
    Step4 = 4,
    Step5 = 5,
    Step6 = 6,
    Step7 = 7,
    Step8 = 8,
    Step9 = 9,
    Step10 = 10,
    Step11 = 11,
    Step12 = 12,
    Ready = 50,
    Closed = 99,
}

export type CareAnamneses = {
    id: number
    patientID: number
    processID: number
    createAt: Date
    editAt: string
    createBy: string
    state: ECareAnamnesisState
}

export type EditPatientAnamnesisHook = {
    CareAnamnesesList: CareAnamneses[]
    isLoading: boolean
    error: boolean
    handleCreateConfirmation: () => Promise<void>
    isOpenConfirmationDialog: boolean
    handleCloseConfirmationDialog: () => void
    handleCreateAnamnesis: () => Promise<void>
}

export type UsePatientsHookProps = {}

export interface AddPatientsFormControl {
    control: Control<any>
}

export type PatientsDialProps = {
    isPatientsEditModeEnabled: boolean
} & Pick<PatientsPageProps, 'setIsMultiEditMode'>

export interface PatientsDialHookProps {
    selectedAction: PatientsDialActionTypes
    handleActionSelect: (
        actionName: PatientsDialActionTypes,
    ) => (e: any) => void
    closeAction: () => void
}

type Address = {
    id: number
    street: string
    city: string
    zip: string
    regNo?: string
    latitude: number
    longitude: number
    sequentialNo?: string
    fullText?: string
}

enum ESex {
    Unknown = 'Unknown',
    Male = 'Male',
    Female = 'Female',
}

export enum EPatientState {
    Waiting = 'Waiting',
    Active = 'Active',
    Hospitalized = 'Hospitalized',
    Ended = 'Ended',
    Dead = 'Dead',
}

export type PatientsCard = {
    id: number
    archived: boolean
    foreign: boolean
    titleBefore?: string
    firstname: string
    surname: string
    titleAfter?: string
    fullName?: string
    sex: ESex
    age: number
    personalNumber?: string
    insuranceNumber?: string
    insuranceNumberFormatted?: string
    insuranceCompanyID?: string
    phone?: string
    email?: string
    state: EPatientState
    note?: string
    nurseNote?: string
    medicationNote?: string
    mobility?: string
    continence?: string
    alergy?: string
    selfService?: string
    permanentAddress?: Address
    careAddress?: Address
    voucherValidTo?: Date
    nurseName?: string
    doctorName?: string
    canReadCareAnamnesis?: boolean
    canEditCareAnamnesis?: boolean
    lastVisitID?: string
    processesExpertises?: string[]
}

export type PatientsCardList = {
    data: PatientsCard[]
    totalItems: number
}

export type PatientCardComponentProps = {
    isLoading: boolean
    className: string
    latLng?: string
    patient: PatientsCard
    setSelectedPatient: Dispatch<SetStateAction<PatientsCard | null>>

    onClick: () => void
    handleOpenCareAnamneses: () => void
}

export interface PatientsFilterHookProps extends LoadingState {
    totalPatients?: number
}

export type PatientsCardTag = ''

export type PatientsCardFilterType =
    | 'patientStates'
    | 'expertiseTypes'
    | 'archived'

export interface PatientsFilterItemValue {
    active: boolean
    value: PatientsCardTag | string | boolean
    name: string
    prefix?: string
    icon?: string
}

export interface PatientsFilterItems
    extends FilterItems<PatientsCardFilterType, string | boolean> {}

export interface PatientsFlatActiveFilter extends PatientsFilterItemValue {
    type: PatientsCardFilterType
}

export interface PatientsFilterProps extends PatientsFilterHookProps {
    filterModalState: OpenStateProps
    handleDeleteItem: (item: PatientsFilterItemValue) => void
    patientsFilters: PatientsFilterItems[]
    handleFilterActivate: ({
        item,
        group,
    }: {
        item: PatientsFilterItemValue
        group: PatientsFilterItems
    }) => void
    resetFilterGroup: (groupType: PatientsCardFilterType) => void
    activeFilters: PatientsFlatActiveFilter[]
    actualFilter: PatientsFlatActiveFilter[]
    resetFilterAllActiveGroup: (resetEverything: boolean) => void
    searchPatientName: string
    setSearchPatientName: Dispatch<SetStateAction<string>>
    isPatientSeachOpen: boolean
    toggleSearchPatientName: () => void
    handleUseFilters: (callback: () => void) => void
    getSameFilterGroup: (
        groupType: PatientsCardFilterType,
    ) => PatientsFilterItems | undefined
}

export interface PatientsQueryResponse extends LoadingState {
    patientsCardList?: PatientsCardList
    isError: boolean
}

export enum EDIT_PATIENTS_ENUM {
    RenamePatients = 'RenamePatients',
}

export interface PatientsPageContentProps {
    patientsQuery: PatientsQueryResponse
    patientsQueryFilters?: PatientFilterQueries
    selectedPatientCards: number[]
    handleSelectAll: () => void
    handlePatientCardSelect: (id: number) => void
    isMultiEditMode?: boolean
    setIsMultiEditMode?: (mode: boolean) => void
    handleMultiplePatientsAction: (action: EDIT_PATIENTS_ENUM) => void
    location: TrackLocationProps
    handleSetNewPage: (page: number) => void
    currentPage: number
}

export interface PatientPageURLParams {
    [key: string]: any
    page?: number
    filters?: PatientFilterQueries
}

type EExpertiseTypes = '720' | '925' | '926'
export interface PatientFilterQueries {
    [key: string]: any
    patientStates?: EPatientState[]
    expertiseTypes?: EExpertiseTypes[]
}

export type PatientsContentHook = Pick<
    PatientsPageProps,
    'isMultiEditMode' | 'setIsMultiEditMode'
>

export interface usePatientsActionWrapperProps
    extends Omit<ModalComponentProps, 'children'> {
    selectedAction: PatientsDialActionTypes
}

export type PatientsDialActionTypes = 'ADD_PATIENT' | ''

export type AddPatientAnamnesisFormStep1Data = {
    name: string
    surname: string
}

export interface PatientsArguments {
    skip: number
    take: number
    token?: string
    queries?: PatientFilterQueries
}

export type AddPatientForm = {
    firstname: string
    surname: string
    dateOfBirth: Date
    insuranceNumber: string
}

export type AddPatientFormFormatted = {
    firstname: string
    surname: string
    dateOfBirth: string
}

export enum ETurgor {
    Adequate = 'přiměřený',
    Elevated = 'zvýšený',
    Reduced = 'snížený',
}

export enum EExcretion {
    Continental = 'kontinentní',
    Incontinent = 'inkontinentní',
}

export enum EFrequency {
    Regular = 'pravidelná',
    Irregular = 'nepravidelná',
}

export enum EConsistency {
    MouledSolid = 'formovaná pevná',
    Constipated = 'zácpovitá',
    Diarrhea = 'průjmovitá',
    Other = 'jiná',
}

export enum EUrinaryEmptying {
    Continental = 'kontinentní',
    Incontinent = 'inkontinentní',
}

export enum ENefrostomyType {
    LDX = 'L. DX.',
    LSIN = 'L. SIN.',
}

export enum ECommunication {
    Adequate = 'přiměřená',
    Inadequate = 'nepřiměřená',
    Other = 'jiné',
}

export enum EConcentration {
    Full = 'plná',
    Partial = 'částečná',
    NotConcentrate = 'nesoustředí se',
}

export enum ESelfCare {
    Separately = 'samostatně',
    WithHelp = 's pomocí',
    WillNotWork = 'neprovede',
}

export enum EHousingIn {
    Flat = 'bytě',
    House = 'rodinném domě',
}

export enum EStressManagement {
    Difficult = 'obtížně',
    Simply = 'jednoduše',
    Other = 'jiné',
}

export enum EPainType {
    Chronic = 'chronická',
    Acute = 'akutní',
}

export interface PointDTO {
    Id: string
    X: number
    Y: number
    Order?: number
    Name: string
    Description: string
    Version?: number
}

export interface PointDTOForm {
    Id: string
    Coordinates: {
        X: number
        Y: number
        ImageWidth: number
        ImageHeight: number
    }
    Order?: number
    Name: string
    Description: string
    Version?: number
}

export type AddPatientFormModalHook = {
    submitMessage: (data: AddPatientForm) => Promise<void>
    formMethods: UseFormReturn<AddPatientForm, any, undefined>
}

export type CareAnamnesisStep0Keys = keyof CareAnamnesisStep0
export type CareAnamnesisStep1Keys = keyof CareAnamnesisStep1
export type CareAnamnesisStep2Keys = keyof CareAnamnesisStep2
export type CareAnamnesisStep3Keys = keyof CareAnamnesisStep3
export type CareAnamnesisStep4Keys = keyof CareAnamnesisStep4
export type CareAnamnesisStep5Keys = keyof CareAnamnesisStep5
export type CareAnamnesisStep6Keys = keyof CareAnamnesisStep6
export type CareAnamnesisStep7Keys = keyof CareAnamnesisStep7
export type CareAnamnesisStep8Keys = keyof CareAnamnesisStep8
export type CareAnamnesisStep9Keys = keyof CareAnamnesisStep9
export type CareAnamnesisStep10Keys = keyof CareAnamnesisStep10
export type CareAnamnesisStep11Keys = keyof CareAnamnesisStep11
export type CareAnamnesisStep12Keys = keyof CareAnamnesisStep12

export interface IAddPatientAnamnesisFormStep0
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    patientId: number
    anamnesisId: number
    setSaveCurrentStepOnClose: any
    canEditCareAnamnesis: boolean
    disabledServerInputs: CareAnamnesisStep0Keys[]
    invalidDateInputs: CareAnamnesisStep0Keys[]
    doctorOptions: { ID: number; FullName: string }[]
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep0, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep0>
}

export interface IAddPatientAnamnesisFormStep1
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    patientId: number
    anamnesisId: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep1Keys[]
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep1, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep1>
}

export interface IAddPatientAnamnesisFormStep2
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    patientId: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    anamnesisId: number
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep2Keys[]
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep2, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep2>
}

export interface IAddPatientAnamnesisFormStep3
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    patientId: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    anamnesisId: number
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep3Keys[]
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep3, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep3>
}

export interface IAddPatientAnamnesisFormStep4
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    patientId: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep4Keys[]
    anamnesisId: number
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep4, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep4>
}

export interface IAddPatientAnamnesisFormStep5
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    patientId: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep5Keys[]
    anamnesisId: number
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep5, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep5>
}

export interface IAddPatientAnamnesisFormStep6
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    patientId: number
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep6Keys[]
    anamnesisId: number
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep6, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep6>
}

export interface IAddPatientAnamnesisFormStep7
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    patientId: number
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep7Keys[]
    anamnesisId: number
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep7, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep7>
}

export interface IAddPatientAnamnesisFormStep8
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    patientId: number
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep8Keys[]
    anamnesisId: number
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep8, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep8>
}

export interface IAddPatientAnamnesisFormStep9
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    patientId: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep9Keys[]
    anamnesisId: number
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep9, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep9>
}

export interface IAddPatientAnamnesisFormStep10
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    patientId: number
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep10Keys[]
    anamnesisId: number
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep10, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep10>
}

export interface IAddPatientAnamnesisFormStep11
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    patientId: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep11Keys[]
    anamnesisId: number
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep11, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep11>
}

export interface IAddPatientAnamnesisFormStep12
    extends AddPatientsAnamnesisFormSteps {
    currentStep: number
    patientId: number
    setSaveCurrentStepOnClose: Dispatch<
        SetStateAction<(() => Promise<void>) | null>
    >
    canEditCareAnamnesis: boolean
    invalidDateInputs: CareAnamnesisStep12Keys[]
    anamnesisId: number
    handleClose: () => void
    setCurrentStep: Dispatch<SetStateAction<number>>
    submitForm: (data: CareAnamnesisStep12, nextStep: boolean) => Promise<void>
    formMethods: UseFormReturn<CareAnamnesisStep12>
}

export type AddPatientsAnamnesisFormStep0Hook = Omit<
    IAddPatientAnamnesisFormStep0,
    | 'formMethods'
    | 'submitForm'
    | 'isLoading'
    | 'error'
    | 'doctorOptions'
    | 'disabledServerInputs'
    | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep1Hook = Omit<
    IAddPatientAnamnesisFormStep1,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep2Hook = Omit<
    IAddPatientAnamnesisFormStep2,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep3Hook = Omit<
    IAddPatientAnamnesisFormStep3,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep4Hook = Omit<
    IAddPatientAnamnesisFormStep4,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep5Hook = Omit<
    IAddPatientAnamnesisFormStep5,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep6Hook = Omit<
    IAddPatientAnamnesisFormStep6,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep7Hook = Omit<
    IAddPatientAnamnesisFormStep7,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep8Hook = Omit<
    IAddPatientAnamnesisFormStep8,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep9Hook = Omit<
    IAddPatientAnamnesisFormStep9,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep10Hook = Omit<
    IAddPatientAnamnesisFormStep10,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep11Hook = Omit<
    IAddPatientAnamnesisFormStep11,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export type AddPatientsAnamnesisFormStep12Hook = Omit<
    IAddPatientAnamnesisFormStep12,
    'formMethods' | 'submitForm' | 'isLoading' | 'error' | 'invalidDateInputs'
>

export interface PutSaveCareAnamnesisStep {
    anamnesisID: number
    data: {
        currentStep: number
        content: string
    }
    token?: string
}

export interface PutSaveCareAnamnesisStepResponse {
    content?: string
    id: number
    patientID: number
    anamnesisID: number
    processID: number
    state: number
    currentStep: number
}

export interface CreateCareAnamnesisResponse {
    content?: string
    id: number
    patientID: number
    processID: number
    state: number
}

export interface CareAnamnesisStepQuery {
    content?: string
    id: number
    patientID: number
    processID: number
    state: number
}

export interface Doctor {
    ID: number
    FullName: string
}

export interface CareAnamnesisStep0 {
    ClientName: string
    InsuranceNumber: string
    InsuranceCompanyID: number
    Address: {
        name: string
        label: string
        position: {
            lon: number
            lat: number
        }
    } | null
    Phone: string
    AddressNote: string
    FamilyContact: string
    PernamentAddress?: string
    FamilyContactNote: string
    Doctors?: Doctor[] | null
    PracticalDoctor?: Doctor | null
    Doctor?: Doctor | null
    IncomingDate?: Date
    TransferFrom: string
    Medication: string
    CareDiagnoses: string
    MedicalDiagnoses: string
    SelectedDoctorID?: number
    SelectedPracticalDoctorID?: number
    SelectedPracitcalDoctorString?: string
}

export interface CareAnamnesisStep1 {
    IsHospitalization?: boolean
    Hospitalization?: string
    IsSurgery?: boolean
    Surgery?: string
    IsInfectiousDiseases?: boolean
    InfectiousDiseases?: string
    PhysiologicalFunctions?: string
    IsBreathing?: boolean
    Breathing?: string
    IsSmoking?: boolean
    Smoking?: string
    IsAlcohol?: boolean
    IsDrugs?: boolean
    IsAlergy?: boolean
    Alergy?: string
    IsOtherServices?: boolean
    OtherServices?: string
}

export interface CareAnamnesisStep2 {
    IsWeightChange?: boolean
    CurrentWeight: string
    CurrentHeight: string
    IncreaseWeight: string
    DecreaseWeight: string
    IsEnteral?: boolean
    Enteral: string
    IsFoodIntakeMouth: boolean
    IsFoodIntakeNasogastricProbe: boolean
    FoodIntakeNasogastricProbe: string | Date
    IsFoodIntakeGastrostomy: boolean
    FoodIntakeGastrostomy: string | Date
    IsFoodIntakePEG: boolean
    FoodIntakePEG: string | Date
    IsFoodIntakeParenterally: boolean
    FoodIntakeParenterally: string
    FluidIntakePerDay: string

    IsFluidIntakeMouth: boolean
    IsFluidIntakeEnterally: boolean
    IsFluidIntakeParenterally: boolean
    IsFluidIntakeSipping: boolean
    IsMucosalPink: boolean
    IsMucosalPale: boolean
    IsMucosalMoist: boolean
    IsMucosalDry: boolean
    IsMucosalSoor: boolean
    IsMucosalAphthae: boolean
    IsMucosalCoatings: boolean
    Turgor?: ETurgor | null
    IsDiet?: boolean
    Diet: string
    IsDiabetes?: boolean
    IsDiabetesTypeDiet: boolean
    IsDiabetesTypePAD: boolean
    IsDiabetesTypeInsulin: boolean
    Insulin: string
    Dosage: string
    IsInsulineApplicationInsulin: boolean
    IsInsulineApplicationPen: boolean
    IsInsulineApplicationPump: boolean
    IsInsulineAppliesMyself: boolean
    IsInsulineAppliesFamily: boolean
    IsInsulineAppliesOther: boolean
}

export interface CareAnamnesisStep3 {
    Excretion?: EExcretion
    Frequency?: EFrequency
    FrequencyPerDay: string
    Consistency?: EConsistency
    ConsistencyOther: string
    IsStomie?: boolean
    Stomie: string
    UrinaryEmptying?: EUrinaryEmptying
    IsIncontinenceAids?: boolean
    IncontinenceAids: string
    IsUrinaryCatheter?: boolean
    UrinaryCatheterDate: string | Date
    UrinaryCatheterSize: string
    IsEpicystostomy?: boolean
    Epicystostomy: string | Date
    IsNefrostomy?: boolean
    Nefrostomy: string | Date
    NefrostomyType?: ENefrostomyType
    IsIslands?: boolean
    Islands: string
    IsTakingDiuretics?: boolean
    TakingDiuretics: string
}

export interface CareAnamnesisStep4 {
    IsSleepDisorders?: boolean
    IsHypnotics?: boolean
    Hypnotics: string
    IsMotionModeWalksAlone: boolean
    IsMotionModeWalksWith: boolean
    IsMotionModeIsPlanted: boolean
    IsMotionModeLies: boolean
    IsMotionModeWheelchairBound: boolean
    IsMotionModeOther: boolean
    MotionModeOther: string
    IsMissingBodyPart?: boolean
    MissingBodyPart: string
    IsCompensatoryAids?: boolean
    CompensatoryAids: string
}

export interface CareAnamnesisStep5 {
    IsHearingGood: boolean
    IsHearingDeteriorated: boolean
    IsHearingDeaf: boolean
    IsHearingHearingAid: boolean
    IsHearingDeafRight: boolean
    IsHearingDeafLeft: boolean
    IsHearingHearingAidRight: boolean
    IsHearingHearingAidLeft: boolean
    IsAirGood: boolean
    IsAirDeteriorated: boolean
    IsAirBlind: boolean
    IsAirGlasses: boolean
    Communication?: ECommunication
    CommunicationOther: string
    IsOrientation?: boolean
    IsOrientationPlace: boolean
    IsOrientationTime: boolean
    IsOrientationPerson: boolean
    Concentration?: EConcentration
}

export interface CareAnamnesisStep6 {
    IsEmotionalStateCalm: boolean
    IsEmotionalStateScared: boolean
    IsEmotionalStateAngry: boolean
    IsEmotionalStateStubborn: boolean
    IsEmotionalStateSelfPitying: boolean
    IsEmotionalStateOther: boolean
    EmotionalStateOther: string
    HygienicCare?: ESelfCare
    Clothing?: ESelfCare
    Nutrition?: ESelfCare
    UsingToilet?: ESelfCare
    KeepingOrder?: ESelfCare
}

export interface CareAnamnesisStep7 {
    HousingIn?: EHousingIn
    IsHousingWithAlone: boolean
    IsHousingWithFamily: boolean
    IsHousingWithPartner: boolean
    IsHousingWithChildren: boolean
    IsHousingWithOther: boolean
    HousingWithOther: string
    IsReleaseHospitalProblem?: boolean
    ReleaseHospitalProblem: string
    IsRequestToSocialRequestSend: boolean
    IsRequestToSocialContactWith: boolean
    RequestToSocialName: string
    IsFamilyCooperation?: boolean
    FamilyCooperation: string
}

export interface CareAnamnesisStep8 {
    IsSexuality?: boolean
    Sexuality: string
}

export interface CareAnamnesisStep9 {
    StressManagement?: EStressManagement
    StressManagementOther: string
}

export interface CareAnamnesisStep10 {
    IsLifePrinciplesReading: boolean
    IsLifePrinciplesTV: boolean
    IsLifePrinciplesRadio: boolean
    IsLifePrinciplesSleep: boolean
    IsLifePrinciplesRest: boolean
    IsLifePrinciplesCompanyOfPeople: boolean
    IsLifePrinciplesSport: boolean
    LifePrinciplesSport: string
}

export interface CareAnamnesisStep11 {
    Points: PointDTOForm[]
    IsVascularInputs?: boolean
    IsVascularInputsPeripheral: boolean
    IsVascularInputsCentral: boolean
    IsVascularInputsOther: boolean
    VascularInputsOther: string
    LocatedAt: string
    DayOfIntroduction: string | Date
    LastChange: string | Date
    IsAroundEntranceCalm: boolean
    IsAroundEntranceReddened: boolean
    IsAroundEntranceIsland: boolean
    IsAroundEntranceSecretion: boolean
}

export interface CareAnamnesisStep12 {
    IsPain?: boolean
    PainType?: EPainType
    IsIntensityOfPainDisturbsSleep: boolean
    IsIntensityOfPainPreventsRest: boolean
    IsIntensityOfPainOnlyWhenMoving: boolean
    IsIntensityOfPainNotRestrict: boolean
    IntensityOfPainValue: string
    IsCharacteristicsOfPainDull: boolean
    IsCharacteristicsOfPainPrickly: boolean
    IsCharacteristicsOfPainCrampy: boolean
    IsCharacteristicsOfPainFiring: boolean
    IsCharacteristicsOfPainClutching: boolean
    IsCharacteristicsOfPainColicky: boolean
    IsCharacteristicsOfPainNeuralgic: boolean
    IsCharacteristicsOfPainIndefinite: boolean
    PainLocalization: string
    IsPainIntensityTypeSteady: boolean
    IsPainIntensityTypeUnsteady: boolean
    IsPainIntensityTypeBearable: boolean
    IsPainIntensityTypeUnbearable: boolean
    IsPainReliefAnalgesics: boolean
    IsPainReliefReliefPosition: boolean
    IsPainReliefReliefRelaxationTechniques: boolean
    IsPainReliefReliefPressureMassage: boolean
    IsPainReliefReliefWarmCompress: boolean
    IsPainReliefReliefColdCompress: boolean
    IsPainReliefReliefOther: boolean
    Analgesics: string
    PainReliefOther: string
}
